<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.1 21.92H17.86C17.0316 21.92 16.36 21.2484 16.36 20.42C16.36 19.5916 17.0316 18.92 17.86 18.92H24.1C24.9284 18.92 25.6 19.5916 25.6 20.42C25.6 21.2484 24.9284 21.92 24.1 21.92Z"
            fill="#007A6F" />
        <path
            d="M17.86 25.14H30.14C30.9684 25.14 31.64 25.8116 31.64 26.64C31.64 27.4684 30.9684 28.14 30.14 28.14H17.86C17.0316 28.14 16.36 27.4684 16.36 26.64C16.36 25.8116 17.0316 25.14 17.86 25.14Z"
            fill="#007A6F" />
        <path
            d="M28.46 31H17.86C17.0316 31 16.36 31.6716 16.36 32.5C16.36 33.3284 17.0316 34 17.86 34H28.46C29.2884 34 29.96 33.3284 29.96 32.5C29.96 31.6716 29.2884 31 28.46 31Z"
            fill="#007A6F" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M28.54 4C30.1584 3.99777 31.4948 5.26385 31.58 6.88001C36.3298 7.41407 39.9392 11.4006 40 16.18V36.36C39.989 41.6022 35.7421 45.849 30.5 45.86H17.5C12.2579 45.849 8.011 41.6022 8 36.36V16.18C8.06083 11.4006 11.6702 7.41407 16.42 6.88001C16.5149 5.26838 17.8456 4.00777 19.46 4H28.54ZM28.58 6.88001H19.46V9.26001H28.58V6.88001ZM37 36.36C37 39.9531 34.0931 42.869 30.5 42.88H17.5C13.9069 42.869 11 39.9531 11 36.36V16.18C11.008 12.9977 13.3187 10.2892 16.46 9.78001C16.7055 11.2427 17.9769 12.3106 19.46 12.3H28.54C30.0231 12.3106 31.2945 11.2427 31.54 9.78001C34.6813 10.2892 36.992 12.9977 37 16.18V36.36Z"
            fill="#007A6F" />
    </svg>
</template>