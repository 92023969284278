<template>
    <svg width="202" height="60" viewBox="0 0 202 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.9503 59.9924C12.4273 59.9924 11.9209 59.9848 11.3979 59.9772C10.327 59.962 9.25615 59.886 8.19356 59.7187C7.20569 59.5667 6.25102 59.2702 5.35446 58.8597C4.4662 58.4493 3.66095 57.9095 2.96363 57.2634C2.258 56.6173 1.6603 55.8875 1.21202 55.0741C0.763736 54.2607 0.44828 53.3789 0.28225 52.4743C0.107919 51.5013 0.016603 50.5207 0 49.5325C0 49.198 0 10.7716 0 10.4523C0.0249045 9.47168 0.107919 8.48347 0.28225 7.51805C0.44828 6.61345 0.763736 5.73166 1.21202 4.91068C1.6603 4.0973 2.2497 3.35994 2.95533 2.7138C3.66095 2.06765 4.4662 1.52033 5.35446 1.10984C6.24272 0.691752 7.19739 0.41049 8.18526 0.258457C9.24785 0.0988217 10.3187 0.0152033 11.3979 0H190.602C191.673 0.0152033 192.736 0.0912201 193.79 0.258457C194.786 0.41049 195.749 0.699354 196.654 1.11745C198.439 1.94603 199.883 3.28392 200.788 4.91068C201.236 5.72406 201.543 6.59825 201.701 7.49525C201.875 8.47586 201.983 9.46408 202 10.4599C202 10.9008 202 49.0764 202 49.5553C201.983 50.5283 201.875 51.5013 201.709 52.4667C201.543 53.3789 201.245 54.2607 200.788 55.0893C200.331 55.9179 199.742 56.6249 199.045 57.2634C198.347 57.9019 197.534 58.4569 196.646 58.8673C195.757 59.2778 194.786 59.5819 193.79 59.7263C192.727 59.886 191.656 59.962 190.585 59.9848C190.087 59.9924 12.942 60 12.942 60L12.9503 59.9924Z"
            fill="#0D0D0D" />
        <path
            d="M37.4435 30.4558C37.4587 27.7952 38.8716 25.3323 41.1355 23.9412C39.6997 21.8887 37.3827 20.6344 34.8758 20.5584C32.2397 20.2848 29.6872 22.132 28.3502 22.132C27.0132 22.132 24.9165 20.566 22.6906 20.6268C19.7659 20.7029 17.1222 22.36 15.6789 24.9066C12.6858 30.1821 14.8888 37.8978 17.8135 42.132C19.2569 44.2224 20.9662 46.5409 23.1844 46.4497C25.3571 46.3661 26.1699 45.0586 28.7984 45.0586C31.4269 45.0586 32.1637 46.4421 34.4276 46.3965C36.7598 46.3509 38.2031 44.2908 39.6313 42.208C40.6569 40.7105 41.4773 39.0913 42.0091 37.3581C39.2363 36.1875 37.4131 33.4661 37.4283 30.4558H37.4435Z"
            fill="#F1F1F1" />
        <path
            d="M33.1592 17.7628C34.4203 16.2196 35.0584 14.266 34.9064 12.2896C32.9617 12.4948 31.1689 13.4222 29.8774 14.8969C28.6012 16.3488 27.9554 18.2417 28.0846 20.1725C30.0521 20.1953 31.9133 19.2983 33.1592 17.7704V17.7628Z"
            fill="#F1F1F1" />
        <path
            d="M64.9343 41.1873H57.5123L55.7271 46.4553H52.582L59.609 26.9722H62.8756L69.9025 46.4553H66.7043L64.9267 41.1873H64.9343ZM58.2796 38.7548H64.1594L61.2575 30.2105H61.1739L58.272 38.7548H58.2796Z"
            fill="#F1F1F1" />
        <path
            d="M85.0966 39.3433C85.0966 43.7598 82.734 46.504 79.1711 46.5953C77.3251 46.6409 75.5931 45.7287 74.7043 44.1095H74.6359V51.1487H71.7188V32.2433H74.5371V34.6074H74.5903C75.5171 33.0035 77.2568 32.0685 79.1104 32.0989C82.7112 32.1597 85.089 34.9495 85.089 39.3509L85.0966 39.3433ZM82.1035 39.3433C82.1035 36.4698 80.6145 34.577 78.3507 34.577C76.0869 34.577 74.6283 36.5078 74.6283 39.3433C74.6283 42.1787 76.1249 44.1247 78.3507 44.1247C80.5765 44.1247 82.1035 42.2471 82.1035 39.3433Z"
            fill="#F1F1F1" />
        <path
            d="M100.724 39.3433C100.724 43.7598 98.361 46.504 94.7981 46.5953C92.9521 46.6409 91.22 45.7287 90.3312 44.1095H90.2628V51.1487H87.3457V32.2433H90.1641V34.6074H90.2173C91.1441 33.0035 92.8837 32.0685 94.7373 32.0989C98.3382 32.1597 100.716 34.9495 100.716 39.3509L100.724 39.3433ZM97.7304 39.3433C97.7304 36.4698 96.2415 34.577 93.9776 34.577C91.7138 34.577 90.2552 36.5078 90.2552 39.3433C90.2552 42.1787 91.7518 44.1247 93.9776 44.1247C96.2035 44.1247 97.7304 42.2471 97.7304 39.3433Z"
            fill="#F1F1F1" />
        <path
            d="M111.044 41.0181C111.257 42.9489 113.133 44.2184 115.701 44.2184C118.269 44.2184 119.925 42.9489 119.925 41.2082C119.925 39.6954 118.861 38.7908 116.339 38.1675L113.817 37.5593C110.239 36.6928 108.583 35.0204 108.583 32.3066C108.583 28.9467 111.508 26.6357 115.671 26.6357C119.834 26.6357 122.606 28.9467 122.697 32.3066H119.758C119.583 30.3606 117.972 29.1899 115.625 29.1899C113.278 29.1899 111.675 30.3758 111.675 32.1089C111.675 33.4848 112.7 34.2982 115.207 34.914L117.349 35.4385C121.345 36.3811 123.001 37.9926 123.001 40.8433C123.001 44.4921 120.099 46.7726 115.481 46.7726C111.166 46.7726 108.249 44.5453 108.059 41.0181H111.044Z"
            fill="#F1F1F1" />
        <path
            d="M129.294 28.8774V32.2374H131.99V34.5483H129.294V42.378C129.294 43.5943 129.833 44.172 131.018 44.1644C131.337 44.1644 131.656 44.134 131.975 44.096V46.3917C131.443 46.4905 130.896 46.5285 130.357 46.5285C127.486 46.4981 126.361 45.4491 126.361 42.6897V34.5483H124.295V32.2374H126.361V28.8774H129.286H129.294Z"
            fill="#F1F1F1" />
        <path
            d="M133.551 39.3466C133.551 34.8768 136.179 32.0718 140.281 32.0718C144.384 32.0718 147.02 34.8768 147.02 39.3466C147.02 43.8164 144.414 46.6214 140.281 46.6214C136.149 46.6214 133.551 43.824 133.551 39.3466ZM144.049 39.3466C144.049 36.2831 142.644 34.4739 140.281 34.4739C137.919 34.4739 136.521 36.2983 136.521 39.3466C136.521 42.3948 137.926 44.2193 140.281 44.2193C142.636 44.2193 144.049 42.4253 144.049 39.3466Z"
            fill="#F1F1F1" />
        <path
            d="M149.426 32.2437H152.206V34.661H152.275C152.662 33.1027 154.09 32.0689 155.693 32.0917C156.027 32.0917 156.362 32.1297 156.688 32.1981V34.9271C156.263 34.7979 155.822 34.7371 155.382 34.7523C153.764 34.6838 152.396 35.9457 152.328 37.5725C152.328 37.7245 152.328 37.8766 152.343 38.021V46.4436H149.426V32.2361V32.2437Z"
            fill="#F1F1F1" />
        <path
            d="M170.115 42.2808C169.72 44.8578 167.213 46.629 163.999 46.629C159.867 46.629 157.307 43.862 157.307 39.415C157.307 34.968 159.882 32.0718 163.878 32.0718C167.874 32.0718 170.274 34.7704 170.274 39.0805V40.0763H160.247V40.2512C160.064 42.2884 161.568 44.0824 163.604 44.2649C163.756 44.2801 163.916 44.2801 164.068 44.2801C165.481 44.4093 166.818 43.6035 167.35 42.2808H170.115ZM160.262 38.0391H167.357C167.463 36.1539 166.02 34.5423 164.136 34.4359C164.045 34.4359 163.961 34.4359 163.87 34.4359C161.887 34.4511 160.269 36.0246 160.254 38.0087V38.0391H160.262Z"
            fill="#F1F1F1" />
        <path
            d="M56.2193 16.2615H57.4728C58.3312 16.3679 59.1213 15.7598 59.2276 14.8932C59.2276 14.8476 59.2352 14.8096 59.2352 14.764C59.2504 13.8974 58.5895 13.3425 57.488 13.3425C56.5764 13.2132 55.7331 13.8518 55.604 14.764C55.604 14.7792 55.604 14.7944 55.604 14.8096H54.229C54.3277 13.1904 55.7103 11.9589 57.3284 12.0502C57.3968 12.0502 57.4652 12.0578 57.5336 12.073C59.372 12.073 60.6634 13.1068 60.671 14.5891C60.671 15.6154 59.9721 16.5047 58.9693 16.7328V16.8468C60.1696 16.9 61.0964 17.9111 61.0508 19.1121C60.8761 20.8301 59.3416 22.0768 57.6247 21.9019C57.6019 21.9019 57.5791 21.9019 57.5563 21.9019C55.8547 22.1376 54.2898 20.9441 54.0543 19.2413C54.0543 19.2033 54.0467 19.1729 54.0391 19.1349H55.4369C55.5204 20.0319 56.3409 20.6097 57.5563 20.6097C58.7718 20.6097 59.5619 19.9635 59.5619 19.0437C59.5619 18.1239 58.8174 17.5462 57.5108 17.5462H56.2193V16.2767V16.2615Z"
            fill="#F1F1F1" />
        <path
            d="M62.6287 19.6656C62.6287 18.3962 63.5783 17.6588 65.2572 17.56L67.1715 17.446V16.8378C67.1715 16.0929 66.6777 15.6672 65.7281 15.6672C64.9533 15.6672 64.4139 15.956 64.2544 16.4501H62.9022C63.0465 15.2339 64.186 14.4585 65.7889 14.4585C67.559 14.4585 68.5541 15.3403 68.5541 16.8302V21.6573H67.2095V20.6615H67.0956C66.6398 21.3836 65.8269 21.8093 64.9761 21.7713C63.8062 21.8929 62.7578 21.0415 62.6363 19.8709C62.6363 19.8025 62.6287 19.7265 62.6211 19.658L62.6287 19.6656ZM67.1639 19.0651V18.4722L65.4395 18.5862C64.4671 18.6546 64.0265 18.9815 64.0265 19.6048C64.0265 20.2282 64.581 20.6083 65.3331 20.6083C66.2447 20.6995 67.0652 20.0305 67.1563 19.1183C67.1563 19.1031 67.1563 19.0803 67.1563 19.0651H67.1639Z"
            fill="#F1F1F1" />
        <path d="M72.2554 15.774V21.6643H70.8652V14.6035H75.5144V15.774H72.2554Z" fill="#F1F1F1" />
        <path
            d="M83.9533 18.123C83.9533 20.3655 82.8214 21.7718 81.0362 21.7718C80.1398 21.8326 79.2965 21.3689 78.8635 20.5859H78.7496V24.0067H77.3594V14.5958H78.704V15.7665H78.8104C79.251 14.9531 80.1094 14.4666 81.0286 14.4818C82.8214 14.5046 83.9609 15.8805 83.9609 18.1306L83.9533 18.123ZM82.5175 18.123C82.5175 16.6331 81.8111 15.7285 80.6336 15.7285C79.4561 15.7285 78.7268 16.6483 78.7268 18.1306C78.7268 19.6129 79.4637 20.5251 80.626 20.5251C81.8111 20.5251 82.5175 19.6205 82.5175 18.123Z"
            fill="#F1F1F1" />
        <path
            d="M85.8098 24.1912V22.9902C85.9617 23.013 86.1137 23.0206 86.2656 23.013C86.8809 23.0586 87.4279 22.6329 87.5418 22.0248L87.6482 21.6827L85.0957 14.5903H86.615L88.3851 20.2004H88.499L90.2615 14.5903H91.7428L89.1144 21.9488C88.5142 23.6667 87.8457 24.2217 86.4023 24.2217C86.2884 24.2217 85.9541 24.2064 85.825 24.1912H85.8098Z"
            fill="#F1F1F1" />
        <path
            d="M92.8223 19.6591H94.2125C94.3492 20.2824 94.9342 20.6093 95.747 20.6093C96.6738 20.6093 97.2436 20.1988 97.2436 19.5678C97.2436 18.8837 96.7346 18.5644 95.747 18.5644H94.6911V17.4774H95.7318C96.575 17.4774 97.0005 17.1505 97.0005 16.5652C97.0005 16.0483 96.5826 15.653 95.7318 15.653C94.9493 15.653 94.3796 15.9875 94.296 16.5424H92.9514C93.1565 15.2121 94.3948 14.2923 95.7318 14.4747C97.4107 14.4747 98.3527 15.2349 98.3679 16.4283C98.3755 17.1125 97.9197 17.713 97.2512 17.8727V17.9867C98.0488 18.0931 98.6338 18.7697 98.6338 19.5754C98.6338 20.9209 97.4942 21.8103 95.747 21.8103C94.2049 21.8103 92.9514 21.1034 92.8223 19.6743V19.6591Z"
            fill="#F1F1F1" />
        <path
            d="M100.639 21.6643V14.6035H102.021V19.4602H102.135L105.387 14.6035H106.769V21.6643H105.387V16.7772H105.28L102.021 21.6643H100.639Z"
            fill="#F1F1F1" />
        <path d="M114.373 15.774H112.147V21.6643H110.749V15.774H108.523V14.6035H114.365V15.774H114.373Z"
            fill="#F1F1F1" />
        <path
            d="M121.876 19.7569C121.481 21.0872 120.197 21.9537 118.815 21.8017C117.045 21.8473 115.571 20.4486 115.525 18.6774C115.525 18.5026 115.525 18.3277 115.556 18.1529C115.32 16.3665 116.566 14.7322 118.351 14.4889C118.503 14.4661 118.655 14.4585 118.815 14.4585C120.782 14.4661 121.967 15.804 121.967 18.0237V18.5102H116.984V18.5862C116.893 19.6124 117.66 20.517 118.686 20.6006C118.746 20.6006 118.807 20.6006 118.868 20.6006C119.552 20.6843 120.213 20.3422 120.547 19.7417H121.891L121.876 19.7569ZM116.976 17.4764H120.539C120.608 16.5414 119.909 15.7204 118.967 15.652C118.914 15.652 118.86 15.652 118.8 15.652C117.804 15.652 116.984 16.4349 116.969 17.4308C116.969 17.446 116.969 17.4612 116.969 17.484L116.976 17.4764Z"
            fill="#F1F1F1" />
        <path
            d="M127.789 14.5967H130.972C132.355 14.5967 133.16 15.2959 133.16 16.3904C133.16 17.1048 132.666 17.7281 131.967 17.8801V17.9941C132.826 18.0473 133.479 18.7617 133.479 19.6206C133.479 20.8518 132.552 21.6575 131.002 21.6575H127.789V14.5967ZM129.172 15.6683V17.5H130.554C131.398 17.5 131.8 17.1732 131.8 16.5804C131.8 16.0332 131.451 15.6759 130.706 15.6759H129.172V15.6683ZM129.172 18.5261V20.5934H130.828C131.648 20.5934 132.089 20.2286 132.089 19.5598C132.089 18.8529 131.587 18.5185 130.653 18.5185H129.172V18.5261Z"
            fill="#F1F1F1" />
    </svg>


</template>