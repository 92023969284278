<template>
    <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.8936 7.94355L20.3421 16.3096C16.4779 20.0899 16.6723 26.4092 20.5365 30.1895L22.6743 32.2811C20.2056 29.8658 20.3999 26.1401 22.8687 23.7249L33.9468 12.8871L39 17.8307L39 3L23.8405 3L28.8936 7.94355Z"
            stroke="#007A6F" stroke-width="3" stroke-linejoin="round" />
        <path
            d="M25.1594 46.0305L33.9502 37.4304C37.8347 33.6302 37.6393 27.2777 33.7549 23.4776L31.215 20.9928C33.6967 23.4207 33.8921 27.5482 31.4104 29.9761L20.0797 41.061L15 36.0915L15 51L30.2391 51L25.1594 46.0305Z"
            stroke="#007A6F" stroke-width="3" stroke-linejoin="round" />
    </svg>

</template>