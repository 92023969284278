<script setup>
import { RouterView, RouterLink } from "vue-router";
import { resource } from "@/components/resources";
import AppstoreIcon from '../components/icons/AppstoreIcon.vue';
import GooglePlayIcon from '../components/icons/GooglePlayIcon.vue';
import { ref } from "vue";


let lang = ref(localStorage.getItem("lang") || "ru");

</script>
<template>
    <div>
        <!-- <footer class="border-t text-white border-gray-300 dark:border-gray-700 dark:bg-[#212121]">
            <div class="container flex semimd:flex-col lg:flex-row-reverse gap-10 justify-between px-2 md:px-5 py-5">
                <div class="hidden semimd:flex justify-between gap-10">
                    <div class="flex flex-col">
                        <h4
                            class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-6 text-center">
                            {{
                                lang == "ru"
                                    ? resource.ru.navLinks[0]
                                    : lang == "uz"
                                        ? resource.uz.navLinks[0]
                                        : resource.en.navLinks[0]
                            }}
                        </h4>
                        <ul class="pt-3">
                            <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                                ? resource.ru.documents.services
                                : lang == 'uz'
                                    ? resource.uz.documents.services
                                    : resource.en.documents.services" :key="index">
                                <RouterLink :to="item.link.includes('https')
                                    ? '/telegram'
                                    : {
                                        path: '/services/',
                                        hash: item.link
                                    }
                                    ">
                                    {{ item?.text }}</RouterLink>
                            </li>
                        </ul>
                    </div>
                    <div class="flex flex-col">
                        <h4
                            class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-5 text-center">
                            {{
                                lang == "ru"
                                    ? resource.ru.navLinks[1]
                                    : lang == "uz"
                                        ? resource.uz.navLinks[1]
                                        : resource.en.navLinks[1]
                            }}
                        </h4>
                        <ul class="pt-3">
                            <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                                ? resource.ru.documents.help
                                : lang == 'uz'
                                    ? resource.uz.documents.help
                                    : resource.en.documents.help" :key="index">
                                <a :href="item.link" target="_blank">{{ item?.text }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="flex flex-col">
                        <h4
                            class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-5 text-center">
                            {{
                                lang == "ru"
                                    ? resource.ru.navLinks[2]
                                    : lang == "uz"
                                        ? resource.uz.navLinks[2]
                                        : resource.en.navLinks[2]
                            }}
                        </h4>
                        <ul class="pt-3">
                            <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                                ? resource.ru.documents.about
                                : lang == 'uz'
                                    ? resource.uz.documents.about
                                    : resource.en.documents.about" :key="index">
                                <RouterLink :to="item.link == 'Services'
                                    ? { path: 'services', hash: item.hash }
                                    : {
                                        path:
                                            item.link == 'services'
                                                ? 'services/'
                                                : '/' + item.link,
                                        hash: item?.link,
                                    }
                                    ">{{ item?.text }}</RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="min-w-fit self-start">
                    <div class="flex flex-col gap-2 mb-3">
                        <a href="https://apps.apple.com/uz/app/digital-pay/id1668041807" target="_blank">
                            <appstore-icon />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=uz.dpay.payment" target="_blank">
                            <google-play-icon />
                        </a>
                    </div>
                    <p v-for="(contact, index) in lang == 'ru'
                        ? resource?.ru?.contacts
                        : lang == 'uz'
                            ? resource.uz.contacts
                            : resource.en.contacts" class="font-semibold my-1 text-sm text-gray-500 dark:text-gray-500"
                        :key="index">
                        {{ contact }}
                    </p>
                </div>
            </div>
        </footer> -->
    </div>
</template>