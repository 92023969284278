<script setup>
import { onMounted, ref } from "vue";
import { resource } from "@/components/resources";
import AppLogo from "@/components/icons/AppLogo.vue";
import AppTopIcon from "@/components/icons/AppTopIcon.vue";
import AppFooter from "./AppFooter.vue";
import AppMenuIcon from "@/components/icons/AppMenuIcon.vue";
import AppstoreIcon from "../components/icons/AppstoreIcon.vue";
import GooglePlayIcon from "../components/icons/GooglePlayIcon.vue";
import AppPhoneIcon from "@/components/icons/AppPhoneIcon.vue";
import AppSendIcon from "@/components/icons/AppSendIcon.vue";
import AppEmailIcon from "@/components/icons/AppEmailIcon.vue";

import { RouterView, RouterLink } from "vue-router";
import AppNav from "./AppNav.vue";



const showContact = ref(false);
let lang = ref(localStorage.getItem("lang") || "ru");
let theme = localStorage.getItem("theme") || false;
let isLight = theme ? ref(false) : ref(true);
let isShown = ref(false);
let html = document.querySelector("html");
html.className = isLight.value ? "" : "dark";

const contactUsData = ref([
  {
    title: "+998 (71) 200 89 00",
    link: "tel:+998712008900",
    icon: AppPhoneIcon,
  },
  {
    title: "E-mail",
    link: "mailto:info@dgb.uz",
    icon: AppEmailIcon,
  },
  {
    title: "Telegram",
    link: "https://t.me/digital_pay_support_bot",
    icon: AppSendIcon,
  },
]);

const switchLight = () => {
  isLight.value = !isLight.value;
  let html = document.querySelector("html");
  html.className = isLight.value ? "" : "dark";

  isLight.value
    ? localStorage.removeItem("theme")
    : localStorage.setItem("theme", "dark");
};

const blockScroll = (bool) => {
  const body = document.querySelector("body");
  if (bool) {
    body.classList.add("overflow-hidden");
  } else {
    body.classList.remove("overflow-hidden");
  }
};

const switchLang = (l) => {
  lang.value = l;
  localStorage.setItem("lang", l);
};

</script>
<template>
  <section class="wraper dark:bg-[#000000]">
    <nav id="top"
      class="absolute z-50 flex justify-between py-4 sm:px-3 phone:py-3 md:px-0 md2:py-5 !max-w-[100vw] shadow-md dark:shadow-none bg-white dark:bg-[#212121] dark:text-white top-0 right-0 left-0">
      <div class="container semimd:px-5 lg:px-3 flex justify-between px-5 poco:px-2 sm2:px-4 poco2:px-5 sm:px-0">
        <div class="flex items-center gap-9">
          <RouterLink to="/"><app-logo /></RouterLink>
        </div>
        <div class="flex items-center font-semibold">
          <div class="w-90 poco2:flex hidden mr-12">
            <div class="group cursor-pointer relative phone:hidden md:block">
              {{
                lang == "ru"
                  ? "Связаться с нами"
                  : lang == "en"
                    ? "Contact us"
                    : "Biz bilan bog'lanish"
              }}
              <div
                class="bg-slate-200 text-black px-4 h-0 w-max group-active:h-24 group-active:py-2 group-hover:h-24 group-hover:py-2 overflow-hidden rounded duration-500 absolute top-8 shadow-lg flex flex-col right-10">
                <a v-for="(item, index) in contactUsData" :key="index" :href="item.link" target="_blank"
                  class="flex gap-2">
                  <component :is="item.icon" />
                  <p>{{ item.title }}</p>
                </a>
              </div>
            </div>
            <RouterLink class="ml-5 phone:hidden md:block" to="/#about">{{
              lang == "ru"
                ? "О нас"
                : lang == "en"
                  ? "About Us"
                  : "Biz haqimizda"
            }}</RouterLink>
            <RouterLink to="Services" class="ml-5 phone:hidden md:block">{{
              lang == "ru" ? "Услуги" : lang == "en" ? "Services" : "Xizmatlar"
            }}</RouterLink>
          </div>
          <div class="mr-10 poco2:flex hidden items-center">
            <span @click="switchLang('ru')" :class="{
              'bg-[#007A6F] text-white': lang === 'ru',
              'dark:bg-[#808080] bg-[#bfbfbf] text-white': lang !== 'ru',
            }" class="dark:text-white phone:hidden semimd:inline font-bold px-2 rounded-md py-1 cursor-pointer mx-3">
              RU
            </span>
            <span @click="switchLang('uz')" :class="{
              'bg-[#007A6F] text-white': lang === 'uz',
              'dark:bg-[#808080] bg-[#bfbfbf] text-white': lang !== 'uz',
            }" class="dark:text-white phone:hidden semimd:inline font-bold px-2 rounded-md py-1 cursor-pointer">
              UZ
            </span>
            <span @click="switchLang('en')" :class="{
              'bg-[#007A6F] text-white': lang === 'en',
              'dark:bg-[#808080] bg-[#bfbfbf] text-white': lang !== 'en',
            }" class="dark:text-white phone:hidden semimd:inline font-bold px-2 rounded-md py-1 cursor-pointer ml-3">
              EN
            </span>
          </div>
          <div class="flex items-center">
            <span
              class="font-semibold poco:inline dark:opacity-70 poco:text-base text-lg spacing tracking-wide phone:hidden">Light</span>
            <label class="relative flex poco:ml-3 poco:mr-0 items-center cursor-pointer mx-3">
              <input type="checkbox" :checked="theme ? true : false" class="sr-only peer" />
              <div @click="switchLight"
                class="w-14 h-7 phone:mr-3 phone:h-6 phone:w-12 bg-[#007A6F] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-7 phone:peer-checked:after:translate-x-[22px] peer-checked:after:border-white after:content-[''] after:absolute after:top-1 phone:after:top-[3px] after:left-1 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 phone:after:h-[18px] phone:after:w-[18px] after:transition-all">
              </div>
            </label>
            <span
              class="font-semibold opacity-80 poco:inline poco:mr-5 poco:text-base dark:opacity-100 text-lg spacing tracking-wide phone:hidden">Dark</span>
          </div>
          <app-menu-icon class="fa-solid fa-bars text-2xl semimd:hidden"
            @click="(isShown = !isShown), blockScroll(true)">
          </app-menu-icon>
        </div>
      </div>
      <app-nav :is-shown="isShown" @close="(isShown = false), blockScroll(false)" />
      <div
        class="fixed flex px-6 py-3 text-white gap-2 cursor-pointer bg-main rounded-full xl:top-[83vh] z-50 duration-300 lg:right-25 phone:right-[1rem] phone:top-[75vh] animate-pulse-scale"
        @click="showContact = !showContact">
        <app-phone-icon />
        <p class="poco2:block hidden">Bog'lanish</p>
      </div>

      <transition-group name="list" tag="div">
        <div v-if="showContact"
          class="flex flex-col gap-4 fixed xl:top-[70vh] z-50 duration-300 lg:right-25 phone:right-2 phone:top-[60vh]">
          <a href="https://t.me/digital_pay_support_bot" target="_blank"
            class="bg-blue-500 text-white rounded-full px-6 py-2 cursor-pointer animate-slide-in flex gap-2">
            <app-send-icon />
            <p>Telegram</p>
          </a>
          <a href="tel:+998712008900" target="_blank"
            class="bg-green-500 text-white rounded-full px-6 py-2 cursor-pointer animate-slide-in flex gap-2">
            <app-phone-icon />
            <p>Telefon</p>
          </a>
        </div>
      </transition-group>
      <div
        class="bg-[#f4f4f4] dark:bg-[#212121] rounded-full p-3 fixed xl:top-[90vh] z-50 duration-300 lg:right-20 phone:right-[1rem] phone:top-[85vh] poco:right-3"
        :class="isShown == false ? 'block' : 'hidden'">
        <a href="#top">
          <AppTopIcon class="fill-black" />
        </a>
      </div>
    </nav>
    <RouterView :data="lang == 'ru' ? resource.ru : lang == 'en' ? resource.en : resource.uz
      " />

    <!-- footer                          -->
    <app-footer />
    <footer class="border-t text-white border-gray-300 dark:border-gray-700 dark:bg-[#212121]">
      <div class="container flex semimd:flex-col lg:flex-row-reverse gap-10 justify-between px-2 md:px-5 py-5">
        <div class="hidden semimd:flex justify-between gap-10">
          <div class="flex flex-col">
            <h4
              class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-6 text-center">
              {{
                lang == "ru"
                  ? resource.ru.navLinks[0]
                  : lang == "uz"
                    ? resource.uz.navLinks[0]
                    : resource.en.navLinks[0]
              }}
            </h4>
            <ul class="pt-3">
              <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                ? resource.ru.documents.services
                : lang == 'uz'
                  ? resource.uz.documents.services
                  : resource.en.documents.services" :key="index">
                <RouterLink v-if="!item.link.includes('https://t.me/')" :to="{
                  path: '/services/',
                  hash: item.link,
                }">
                  {{ item?.text }}
                </RouterLink>
                <a v-else class="cursor-pointer" :href="item.link" target="_blank">
                  {{ item?.text }}
                </a>
              </li>
            </ul>
          </div>
          <!-- Oferta -->
          <div class="flex flex-col">
            <h4
              class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-5 text-center">
              {{
                lang == "ru"
                  ? resource.ru.navLinks[1]
                  : lang == "uz"
                    ? resource.uz.navLinks[1]
                    : resource.en.navLinks[1]
              }}
            </h4>
            <ul class="pt-3">
              <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                ? resource.ru.documents.help
                : lang == 'uz'
                  ? resource.uz.documents.help
                  : resource.en.documents.help" :key="index">
                <a :href="item.link" target="_blank">{{ item?.text }}</a>
              </li>
            </ul>
          </div>
          <div class="flex flex-col">
            <h4
              class="shadow-custom dark:shadow-darkCustom rounded-xl md:text-xl text-black dark:text-white max-w-max sm:text-2xl text-lg p-1 px-5 text-center">
              {{
                lang == "ru"
                  ? resource.ru.navLinks[2]
                  : lang == "uz"
                    ? resource.uz.navLinks[2]
                    : resource.en.navLinks[2]
              }}
            </h4>
            <ul class="pt-3">
              <li class="mb-3 dark:text-white text-black text-lg" v-for="(item, index) in lang == 'ru'
                ? resource.ru.documents.about
                : lang == 'uz'
                  ? resource.uz.documents.about
                  : resource.en.documents.about" :key="index">
                <RouterLink :to="item.link == 'Services'
                  ? { path: 'services', hash: item.hash }
                  : {
                    path:
                      item.link == 'services'
                        ? 'services/'
                        : '/' + item.link,
                    hash: item?.link,
                  }
                  ">{{ item?.text }}</RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="min-w-fit self-start">
          <div class="flex flex-col gap-2 mb-3">
            <a href="https://apps.apple.com/uz/app/digital-pay/id1668041807" target="_blank">
              <appstore-icon />
            </a>
            <a href="https://play.google.com/store/apps/details?id=uz.dpay.payment" target="_blank">
              <google-play-icon />
            </a>
          </div>
          <p v-for="(contact, index) in lang == 'ru'
            ? resource?.ru?.contacts
            : lang == 'uz'
              ? resource.uz.contacts
              : resource.en.contacts" class="font-semibold my-1 text-sm text-gray-500 dark:text-gray-500" :key="index">
            {{ contact }}
          </p>
        </div>
      </div>
    </footer>
  </section>
</template>

<style>
@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.animate-pulse-scale {
  animation: pulse-scale 1.5s infinite ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(50px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-in-out;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
