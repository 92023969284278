<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M24.7 14.24C22.0099 14.1808 19.4095 15.2082 17.4863 17.09C15.5631 18.9718 14.4793 21.5493 14.48 24.24C14.465 26.3903 15.13 28.4903 16.38 30.24C18.3776 32.913 19.4895 36.1437 19.56 39.48C19.8792 41.9983 22.0215 43.8863 24.56 43.8863C27.0985 43.8863 29.2408 41.9983 29.56 39.48C29.4586 36.2849 30.4537 33.1512 32.38 30.6C33.7427 28.8919 34.5084 26.7845 34.56 24.6C34.6504 19.0349 30.2628 14.4248 24.7 14.24ZM22.18 38.86C21.4452 38.7396 20.9058 38.1046 20.9058 37.36C20.9058 36.6154 21.4452 35.9804 22.18 35.86H26.78C27.5148 35.9804 28.0542 36.6154 28.0542 37.36C28.0542 38.1046 27.5148 38.7396 26.78 38.86H22.18ZM29.86 23.92C29.8488 24.7408 30.4994 25.4182 31.32 25.44L31.4 25.46C32.2019 25.4492 32.8493 24.8018 32.86 24C32.8975 21.8748 32.0876 19.822 30.6092 18.2948C29.1308 16.7676 27.1054 15.8916 24.98 15.86C24.1516 15.86 23.48 16.5316 23.48 17.36C23.48 18.1884 24.1516 18.86 24.98 18.86C27.695 18.9772 29.8412 21.2026 29.86 23.92Z"
            fill="#007A6F" />
        <path
            d="M24.56 12.42C25.3839 12.4092 26.0492 11.7439 26.06 10.92V5.5C26.06 4.9641 25.7741 4.46891 25.31 4.20096C24.8459 3.93301 24.2741 3.93301 23.81 4.20096C23.3459 4.46891 23.06 4.9641 23.06 5.5V10.92C23.0708 11.7439 23.7361 12.4092 24.56 12.42Z"
            fill="#007A6F" />
        <path
            d="M39.18 9.74C38.5944 9.15509 37.6456 9.15509 37.06 9.74L33.32 13.46C32.8916 13.889 32.7635 14.5337 32.9954 15.0938C33.2274 15.654 33.7737 16.0195 34.38 16.02C34.7781 16.0219 35.1602 15.8633 35.44 15.58L39.18 11.86C39.7649 11.2744 39.7649 10.3256 39.18 9.74Z"
            fill="#007A6F" />
        <path
            d="M43.26 22.06H38C37.1716 22.06 36.5 22.7316 36.5 23.56C36.5 24.3884 37.1716 25.06 38 25.06H43.26C44.0884 25.06 44.76 24.3884 44.76 23.56C44.76 22.7316 44.0884 22.06 43.26 22.06Z"
            fill="#007A6F" />
        <path
            d="M10.76 22.06H5.5C4.67157 22.06 4 22.7316 4 23.56C4 24.3884 4.67157 25.06 5.5 25.06H10.76C11.5884 25.06 12.26 24.3884 12.26 23.56C12.26 22.7316 11.5884 22.06 10.76 22.06Z"
            fill="#007A6F" />
        <path
            d="M15.58 15.58C15.8659 15.3014 16.0271 14.9192 16.0271 14.52C16.0271 14.1208 15.8659 13.7386 15.58 13.46L11.86 9.74C11.4861 9.33869 10.9229 9.1735 10.3914 9.30923C9.85996 9.44496 9.44496 9.85995 9.30923 10.3914C9.1735 10.9229 9.3387 11.4861 9.74 11.86L13.46 15.58C13.7398 15.8633 14.1219 16.0219 14.52 16.02C14.9186 16.0242 15.3015 15.8652 15.58 15.58Z"
            fill="#007A6F" />
    </svg>
</template>