<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M32.0004 17.5198C31.9351 20.7866 29.2678 23.4005 26.0004 23.3998C22.772 23.3466 20.1646 20.7481 20.1004 17.5198V15.2598C20.1 13.9286 20.6369 12.6535 21.5895 11.7236C22.542 10.7936 23.8296 10.2875 25.1604 10.3198H27.0404C28.3541 10.3145 29.6157 10.8326 30.5465 11.7596C31.4772 12.6866 32.0004 13.9462 32.0004 15.2598V17.5198ZM25.1004 13.2598C23.9959 13.2598 23.1004 14.1552 23.1004 15.2598L23.1804 17.5198C23.1804 19.1214 24.4788 20.4198 26.0804 20.4198C27.6821 20.4198 28.9804 19.1214 28.9804 17.5198V15.2598C28.9804 14.1552 28.085 13.2598 26.9804 13.2598H25.1004Z"
            fill="#007A6F" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M46.9604 30.5998L47.1804 33.7198C47.4116 34.6963 47.1859 35.7249 46.5671 36.5148C45.9484 37.3048 45.0038 37.7703 44.0004 37.7798H35.0204C34.237 38.6149 33.1454 39.092 32.0004 39.0998H20.0004C18.7976 39.1159 17.6461 38.6131 16.8404 37.7198C16.6543 37.7405 16.4665 37.7405 16.2804 37.7198H7.10044C6.12438 37.683 5.21479 37.2157 4.61646 36.4437C4.01813 35.6716 3.79255 34.6742 4.00044 33.7198L4.30044 30.5998C4.59228 29.1111 5.88365 28.028 7.40044 27.9998H16.0004C16.3594 27.9799 16.7189 28.0274 17.0604 28.1398C17.8277 27.1813 18.9927 26.6284 20.2204 26.6398H31.7804C32.9363 26.6355 34.0376 27.1314 34.8004 27.9998C34.9397 27.9801 35.0811 27.9801 35.2204 27.9998H43.8604C45.3772 28.028 46.6686 29.1111 46.9604 30.5998ZM35.1004 34.7198H44.1604C44.2216 34.602 44.2289 34.4634 44.1804 34.3398V33.9398L43.9804 31.0998L43.8604 30.9398H34.8004C33.9549 30.9429 33.1479 30.5866 32.5804 29.9598C32.382 29.7225 32.0898 29.5837 31.7804 29.5798H20.2204C19.8854 29.5841 19.5698 29.7382 19.3604 29.9998C18.7895 30.6776 17.9466 31.066 17.0604 31.0598C16.6976 31.0615 16.3378 30.9936 16.0004 30.8598H7.50044C7.38044 30.8598 7.38044 30.8998 7.38044 31.0198L7.18044 33.8598V34.2598C7.12568 34.3806 7.12568 34.5191 7.18044 34.6398H16.3604C16.5733 34.6203 16.7876 34.6203 17.0004 34.6398C17.8132 34.6549 18.5824 35.0105 19.1204 35.6198C19.3411 35.8781 19.6609 36.0307 20.0004 36.0398H32.0804C32.4206 36.0332 32.7414 35.8801 32.9604 35.6198C33.5228 35.0429 34.2948 34.7182 35.1004 34.7198Z"
            fill="#007A6F" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M43.9404 20.5198C43.9404 22.9499 41.9705 24.9198 39.5404 24.9198C38.37 24.9251 37.2457 24.4639 36.4162 23.6382C35.5867 22.8124 35.1204 21.6902 35.1204 20.5198V18.8398C35.1204 17.8585 35.5103 16.9174 36.2041 16.2235C36.898 15.5296 37.8391 15.1398 38.8204 15.1398H40.2404C42.2839 15.1398 43.9404 16.7964 43.9404 18.8398V20.5198ZM38.3255 18.4248C38.1942 18.5561 38.1204 18.7342 38.1204 18.9198V20.5998C38.2056 21.3139 38.8113 21.8516 39.5304 21.8516C40.2496 21.8516 40.8552 21.3139 40.9404 20.5998V18.9198C40.9404 18.5332 40.627 18.2198 40.2404 18.2198H38.8204C38.6348 18.2198 38.4567 18.2936 38.3255 18.4248Z"
            fill="#007A6F" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.8446 23.6382C14.0152 24.4639 12.8909 24.9251 11.7204 24.9198C10.5535 24.9198 9.43433 24.4562 8.60917 23.6311C7.78401 22.8059 7.32044 21.6868 7.32044 20.5198V18.8398C7.32044 16.7964 8.97699 15.1398 11.0204 15.1398H12.4404C14.4839 15.1398 16.1404 16.7964 16.1404 18.8398V20.5198C16.1405 21.6902 15.6741 22.8124 14.8446 23.6382ZM11.0204 18.2198C10.6338 18.2198 10.3204 18.5332 10.3204 18.9198V20.5998C10.4056 21.3139 11.0113 21.8516 11.7304 21.8516C12.4496 21.8516 13.0552 21.3139 13.1404 20.5998V18.9198C13.1404 18.5332 12.827 18.2198 12.4404 18.2198H11.0204Z"
            fill="#007A6F" />
    </svg>
</template>